<template>
  <footer class="footer border-top">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ new Date().getFullYear() }} © GICAM.</div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">En quelques clics.</div>
        </div>
      </div>
    </div>
  </footer>
</template>
