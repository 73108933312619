<script>
import { layoutComputed } from "@/state/helpers";

import Horizontal from "@/layouts/horizontal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Horizontal,
  },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed,
    ...mapGetters(["transitionName", "transitionMode"]),
  },
  methods: {},
  mounted() {
    // document.querySelector("html").setAttribute('dir', 'rtl');
  },
};
</script>

<template>
  <div>
    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <transition :name="transitionName" :mode="transitionMode" appear>
        <div v-if="$slots.default" style="width: 100%; min-height: 100vh">
          <slot></slot>
        </div>
      </transition>
      <div class="mb-5"></div>
    </Horizontal>
  </div>
</template>

<style lang="css">
.scale-slide-left-enter-active,
.scale-slide-left-leave-active,
.scale-slide-right-enter-active,
.scale-slide-right-leave-active {
  /* position: absolute; */
  transition: all 0.3s ease;
}

.scale-slide-left-enter-from {
  opacity: 0;
  transform: translateX(50%) scale(0.8);
}

.scale-slide-right-enter-from {
  opacity: 0;
  transform: translateX(-50%) scale(1.2);
}

/* .scale-slide-left-leave-to {
  opacity: 0;
  transform: translateX(-50%) scale(0.8);
}

.scale-slide-right-leave-to {
  opacity: 0;
  transform: translateX(50%) scale(3);
} */
</style>
