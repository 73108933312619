<script>
import NavBar from "@/components/nav-bar.vue";
import RightBar from "@/components/right-bar.vue";
import Footer from "@/components/footer.vue";
import { mapGetters } from "vuex";

export default {
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters("system", ["isAdmin"]),
  },
  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
  components: {
    NavBar,
    RightBar,
    Footer,
  },
};
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <NavBar v-if="!$route.path.startsWith('/login')" />

      <!-- ========== App Menu ========== -->
      <div
        v-if="!$route.path.startsWith('/login')"
        class="app-menu navbar-menu"
      >
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- Dark Logo-->
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </router-link>
          <!-- Light Logo-->
          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="17" />
            </span>
          </router-link>
          <button
            type="button"
            class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i class="ri-record-circle-line"></i>
          </button>
        </div>
        <div id="scrollbar">
          <div class="container-fluid">
            <ul
              class="navbar-nav h-100"
              id="navbar-nav"
              style="flex-direction: row !important"
            >
              <li class="menu-title">
                <span data-key="t-menu"> Menu</span>
              </li>

              <li v-if="isAdmin" class="nav-item">
                <router-link
                  :to="'/'"
                  data-key="t-nft"
                  class="nav-link menu-link"
                  :class="{ active: $route.path == '/' }"
                >
                  <i class="ri-dashboard-2-line"></i>
                  <span data-key="t-dashboards">Dashboard</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  :to="{ name: 'campagnes-events' }"
                  data-key="t-nft"
                  class="nav-link menu-link"
                  :class="{
                    active: $route.path.startsWith('/campaign/events'),
                  }"
                >
                  <i class="ri-calendar-event-fill"></i>
                  <span data-key="t-dashboards">Évènements</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  :to="{ name: 'campagnes-sms' }"
                  data-key="t-nft"
                  class="nav-link menu-link"
                  :class="{
                    active: $route.path.startsWith('/campaign/sms'),
                  }"
                >
                  <i class="ri-message-2-line"></i>
                  <span data-key="t-messages"> SMS</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="isAdmin">
                <router-link
                  :to="{ name: 'system-admins' }"
                  data-key="t-nft"
                  class="nav-link menu-link"
                  :class="{
                    active: $route.path.startsWith('/system/admins'),
                  }"
                >
                  <i class="ri-settings-2-line"></i>
                  <span data-key="t-messages"> Administration</span>
                </router-link>
              </li>

              <!-- <li class="nav-item">
                <a
                  class="nav-link"
                  href="#sidebarlanding"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarlanding"
                >
                  <i class="ri-rocket-line"></i> Campagnes
                </a>
                <div class="collapse menu-dropdown" id="sidebarlanding">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link
                        class="nav-link menu-link"
                        :to="{ name: 'campagnes-events' }"
                      >
                        <span>Événements</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        class="nav-link menu-link"
                        :to="{ name: 'campagnes-sms' }"
                      >
                        <span>SMS</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#sidebarlanding"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarlanding"
                >
                  <i class="ri-settings-2-line"></i>
                  Administration
                </a>
                <div class="collapse menu-dropdown" id="sidebarlanding">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link class="nav-link menu-link" :to="'/'">
                        <span>Utilisateurs</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link menu-link" :to="'/'">
                        <span>Paramètres</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <router-link
                  :to="'/'"
                  data-key="t-cases"
                  class="nav-link menu-link"
                >
                  <i class="ri-pie-chart-line"></i>
                  <span data-key="t-cases"> Statistiques</span>
                </router-link>
              </li> -->

              <li v-show="false" class="nav-item">
                <router-link
                  :to="'/'"
                  data-key="t-cases"
                  class="nav-link menu-link"
                >
                  <i class="ri-settings-2-line"></i>
                  <span data-key="t-cases"> Configurations</span>
                </router-link>
              </li>
            </ul>
          </div>
          <!-- Sidebar -->
        </div>
        <!-- Left Sidebar End -->
        <!-- Vertical Overlay-->
        <div class="vertical-overlay"></div>
      </div>

      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div
          class="page-content"
          style="height: 100vh; overflow-y: scroll; margin-top: 0px !important"
        >
          <!-- Start Content-->
          <div class="container-fluid">
            <slot />
          </div>
        </div>
        <Footer />
      </div>
      <RightBar />
    </div>
  </div>
</template>
